














































import { Vue } from "vue-property-decorator";
import { Component } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
import ConditionalBackLink from "@/views/legal/components/ConditionalBackLink.vue";

@Component({
  components: { ConditionalBackLink },
  mixins: [validationMixin],
  validations: {
    emailInput: { required, email },
    messageInput: { required },
  },
})
export default class Contact extends Vue {
  @Action("contact/sendMessage") sendMessage!: (data: any) => Promise<void>;
  @Getter("contact/messageLoading") messageLoading!: boolean;

  emailInput = "";
  messageInput = "";

  get emailErrors(): any[] {
    const errors: any[] = [];
    if (!this.$v.emailInput!.$dirty) return errors;
    !this.$v.emailInput!.email &&
      errors.push(this.$t("forms.contact.invalidEmail"));
    !this.$v.emailInput!.required &&
      errors.push(this.$t("forms.contact.emailRequired"));
    return errors;
  }

  get messageErrors(): any[] {
    const errors: any[] = [];
    if (!this.$v.messageInput!.$dirty) return errors;
    !this.$v.messageInput!.required &&
      errors.push(this.$t("forms.contact.messageRequired"));
    return errors;
  }

  submit(): void {
    this.$v.$touch();
    if (!this.$v.$invalid && this.messageErrors.length === 0) {
      this.sendMessage({
        email: this.emailInput,
        body: this.messageInput,
      });
      // reset form including validators
      (this.$refs.form as HTMLFormElement).reset();
      this.$v.emailInput.$reset();
      this.$v.messageInput.$reset();
    }
  }

  beforeDestroy() {
    this.emailInput = "";
    this.messageInput = "";
  }
}
